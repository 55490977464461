<template>
  <div>
    <div class="back_img" :style="{background: `#070E20 url(${imgChange('/img/common/kmgm_onex2.png')}) repeat`}"></div>
    <div class="main_wrap">
      <div class="page_wrap"
           :style="{background: `url(${require('@/assets/v2/img/bg_room.jpg')}) no-repeat center`, backgroundSize: 'cover'}">
        <div class="con_wrap">
          <div class="login_box">
            <div v-if="isVictory()" class="chpa">
              <div class="img">
                <img src="/img/login/chpa.png">
              </div>
            </div>

            <div class="login_input_area">
              <div class="title">
                <div class="text">LOGIN</div>
              </div>
              <div class="box input box_id">
                <div class="text"><input type="text" v-model="id" placeholder='아이디' @keypress.13="loginFromId()"></div>
              </div>
              <div class="box input box_password">
                <div class="text"><input type="password" v-model="pw" placeholder='비밀번호' @keypress.13="login()"></div>
              </div>

              <div class="gologin" @click="login()">
                <div class="text">입장하기</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as authAPI from '../plugins/apiHelper_auth'
import pokerCommon from '@/pokerCommon'
import store from "../store";

export default {
  data() {
    return {
      id: '',
      pw: ''
    }
  },
  created() {
    window.opener && window.close();
  },
  methods: {
    loginFromId() {
      if (String(this.id).length > 0 && String(this.pw).length > 0) {
        this.login()
      }
    },
    async login() {
      try {
        const data = await authAPI.login({id: this.id, pw: this.pw})

        // this.$store.state.loginToken = data.loginToken;
        // localStorage.setItem(`loginToken`, data.loginToken)
        await this.$store.dispatch('auth_login', data);

        await this.$router.replace({name: 'lobby'});
      } catch (e) {
        if (e == -9999) {
          alert('점검 중 입니다')
        } else {
          alert('가입하지 않은 아이디이거나, 잘못된 비밀번호입니다.')
        }
        console.log(e)
      }
    },
    imgChange(img) {
      return pokerCommon.imgChange(img);
    },
    isVictory() {
      return pokerCommon.isVictory();
    },
  },
  async mounted() {
    await store.dispatch('hide_wrap_loading');
  }
}
</script>

<style lang="scss" scoped>

.main_wrap {
  position: relative;
  max-width: 800px;
  margin: 0 auto;

  .page_wrap {
    position: fixed;
    width: 100%;
    max-width: 800px;
    height: 100%;
    // background:url('/img/login/bg.png') no-repeat center;
    // background-size:cover;

    .con_wrap {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;

      .login_box {
        position: relative;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 296px;

        .chpa {
          display: flex;
          justify-content: center;

          .img {
            img {
              width: 150px;
            }
          }
        }

        .logo {
          // display:flex;
          // margin-left:-57px;
          // margin-top:-50px;
          // justify-content: center;
          margin-bottom: 5px;
          width: 255px;
          height: 125px;
          margin: 0 auto;
          padding-right: 8px;

          img {
            // width:120%;
            width: 100%;
          }

        }

        .login_input_area {
          width: 182px;
          margin: 0 auto;

          .title {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-bottom: 12px;

            .text {
              img {
                width: 60px;
              }

              font-size: 23px;
              font-family: "Godo B";
              letter-spacing: -2px;
              background: -webkit-linear-gradient(#fff, #a8a7a7);
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;
              filter: drop-shadow(1px 1px #000);
              opacity: 0.7;
              padding: 0 5px;
            }
          }

          .box {
            position: relative;
            border-radius: 20px;
            height: 38px;

            .img {
              position: absolute;
              top: 10px;
              left: 15px;
            }

            .text {
              text-align: center;

              input {
                font-family: "Godo M", sans-serif;
                width: 100px;
                height: 100%;
                background-color: transparent;
                border: 0;
                color: #6f6f6f;
                font-weight: bold;
                font-size: 14px;

                &::placeholder {
                  color: #6f6f6f;
                  // text-align: center;
                }
              }
            }
          }

          .box.input {
            display: flex;
            justify-content: center;
            // background: #3F3E1D;
            margin-bottom: 6px;
          }

          .box_id {
            background: url('/img/login/id_back.png') no-repeat center;
            background-size: cover;
          }

          .box_password {
            background: url('/img/login/password_back.png') no-repeat center;
            background-size: cover;
          }

          .gologin {
            display: flex;
            border-radius: 20px;
            height: 38px;
            justify-content: space-between;
            align-items: center;
            margin-top: 10px;
            margin-bottom: 8px;
            background: url('/img/login/enter_bg.png') no-repeat center;
            background-size: cover;
            // background: #3A50C5;
            // padding:0 24px;

            .text {
              font-family: "Godo B", sans-serif;
              font-size: 20px;
              background: -webkit-linear-gradient(#fff, #a8a7a7);
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;
              filter: drop-shadow(1px 1px #000);
              opacity: 0.7;
              padding-left: 25px;
            }
          }

          .gosignup {
            display: flex;
            align-items: center;
            padding: 0 15px;

            .text {
              font-family: "Godo B", sans-serif;
              font-size: 20px;
              letter-spacing: -2px;
              background: -webkit-linear-gradient(#fff, #a8a7a7);
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;
              filter: drop-shadow(1px 1px #000);
              opacity: 0.7;

            }
          }
        }


      }
    }
  }

}

</style>